import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Panel, Button, Table } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import TextareaAutosize from 'react-textarea-autosize';

import ReportIcon from '../../../assets/images/report.png';
import ReportIconTrustbucks from '../../../assets/images/trustbucks/report.png';
import ReportIconT365 from '../../../assets/images/t365/report.png';
import ReportIconPacificCapital from '../../../assets/images/pacificCapital/report.png';
import Calendar from '../../../assets/images/calendar.png';

import Alert from "../Alert";
import ComponentHeader from "../../componentHeader";
import Dropdowns from '../apiData/dropdowns';
import Form from "../../../core/Form";
import Modal from '../Modal';
import Multiselect from "./customMultiselect";
import Select from "../Select";
import Spinner from '../../Spinner';

import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/pacificCapital/generateReports.scss";
import '../../../assets/css/pacificCapital/apiData.scss';
import "../../../assets/css/t365/generateReports.scss";
import '../../../assets/css/t365/apiData.scss';
import "../../../assets/css/trustbucks/generateReports.scss";
import '../../../assets/css/trustbucks/apiData.scss';
import "../../../assets/css/generateReports.css";
import '../../../assets/css/apiData.css';

import { whiteLabel } from '../../../config';
import { crudActions } from "../../../services/crudActions";
import { saveAs } from 'file-saver';
import { getColor, getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
import { FETCH_PERMISSIONS } from '../../../actions/types';

const INTEGER_MAX = 2147483647;

const store =  require('../../../reducers/index');

class GenerateReport extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),

    depositMethod: [],
    depositStatus: [],
    sortBy: [],
    sortByDate: [],
    reportType: [],
    currency: [],
    baseCurrencies: [],
    displayMode: [],
    payoutMethod: [],
    payoutCountry: [],
    payoutStatus: [],
    settlementStatuses: [],
    countries: [],
    properties: [],
    transactionId: "",
    clientName: "",
    companiesClients: {},
    clientMids: [],
    clientPayoutMids: [],
    defaultTimezone: "",
    timeStampList:[],
    timeZones:[],

    trafficTypes: [{
      value: "FTD",
      label: "FTD"
    }, {
      value: "MIXED",
      label: "Mixed"
    }, {
      value: "TRUSTED",
      label: "Trusted"
    }],

    reportFormData: {
      clientIds: [],
      depositMethod: [],
      depositStatus: [],
      currency: [],
      sortBy: "",
      reportType: "",
      displayMode: "",
      transactionId: "",
      sortByDate: "DAY",
      companyId: "",
      midIds: [],
      timeStampList: "",
      timeZone: ""
    },

    reportFormRules: [{
      name: "clientIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "companyId",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "reportType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeStampList",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "displayMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "sortByDate",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currency",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositMethod",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositStatus",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "midIds",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      value:"",
      rules: {
        trim: true,
        required: false,
        pattern: "^([0-9a-zA-z#?!@$%^&*-]+)((, +| +, +| + ,|, |,| ,| , )([0-9a-zA-z#?!@$%^&*-]+))*$"
      }
    }],

    reportForm: {},

    endpointGenerateMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/clients/list"
      },
      PAYOUT: {
        LIST: "v1/reports/payout"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/clients/deposits_payouts"
      }
    },

    endpointXlsxMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list/xlsx",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed/xlsx"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions/xlsx"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/clients/xlsx"
      },
      PAYOUT: {
        LIST: "v1/reports/payout/xlsx"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list/xlsx"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list/xlsx"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance/xlsx"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/clients/deposits_payouts/xlsx"
      }
    },

    defaultTimePeriods: {
      GROSS_PROFIT: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SERVER_LOGS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      TRANSACTIONS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      PAYOUT: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      RUNNING_BALANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SETTLEMENTS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      PERFORMANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      DEPOSITS_PAYOUTS: {
        id: 0,
        value: 'today',
        label: 'Today'
      }
    },

    reportsGenerated: {},
    savedGeneratedReport: {},
    savedSelectedCompany: "",
    generatedReportType: "",
    
    modalData: "{}",
    modalType: "json",

    access: [],

    loadingRBReportId: "",
    rbIntervalID: null,
    isLoading: false,
    isLoadingLookups: true,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    successMessage: "",
    enableButton: false,

    isSortSettingsOpen: false,
    isExportSettingsOpen: false,
    isReportErrorFields: false,
    showModal: false,
    isModalLoading: false,
    currentPage: 1,
    rowsAmount: 100,
    clickButton:false,
    cnt: 0,
    periodId: 0 // today
  };

  sortNode = null;
  exportNode = null;

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
    clearInterval(this.state.rbIntervalID);
  };

  handleClickOutside = (event) => {
    if (this.sortNode && this.sortNode.contains(event.target)) {
      this.setState({
        isExportSettingsOpen: false
      });

      return;
    }

    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false,
      isSortSettingsOpen: false,
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  thisMonth = true;

  componentDidMount() {
    const { reportFormRules, reportFormData } = this.state;
    this.setState({
      reportForm: new Form(reportFormData, reportFormRules)
    }, this.loadLookupsData);

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });
  };

  loadLookupsData() {
    const { reportForm } = this.state;

    crudActions.get(`v1/clients/current`).then(
      data => {
        if (data) {
          const currentCompanyId = data.companyId;
          this.setState({
            reportForm: Object.assign(reportForm, {
              clientIds: [data.id],
              companyId: currentCompanyId,
              midIds: data.mids || []
            }),
            clientName: data.name,
            companiesClients: {
              [currentCompanyId]: {
                value: data.id,
                label: data.name
              }
            },
            clientMids: data.mids || [],
            clientPayoutMids: data.payoutMids || [],
            defaultTimezone: data.defaultTimezone || ""
          });
          
          Promise.all([
            crudActions.get(`v1/reports/company/${currentCompanyId}/lookups`)
          ]).then(
            (companyData) => {
              const lookups = companyData[0];
              if (lookups) {
                // remove Activity report on FE, delete this after BE update
                const filtered = Object.assign(lookups, {
                  reportType: lookups.reportType.filter(type => type.value !== "ACTIVITY" && type.value !== "SERVER_LOGS"),
                  displayMode: lookups.displayMode.filter(mode => mode.value === "LIST"),
                  sortBy: Object.assign(lookups.sortBy, {
                    PERFORMANCE: [{
                      value: "EMAIL",
                      label: "Email"
                    }, {
                      value: "IP",
                      label: "IP Address"
                    }],
                    RUNNING_BALANCE: [{
                      value: "DATE",
                      label: "Date"
                    }]
                  }),
                  depositPayoutStatus: this.unionBy([lookups.depositStatus, lookups.payoutStatus], "value")
                });
                const reportType = lookups.reportType[0];
                this.setState({
                  ...filtered,
                  isLoadingLookups: false
                }, () => {
                  this.onValueChange(reportType, "reportType");
                });
              }
            }
          );
        }
      }
    );
  };

  checkAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }
    return foundPermission.state;
  };

  unionBy = (arrays, iteratee) => {
    const map = {};

    arrays.forEach((array) => {
      array.forEach((object) => {
        map[object[iteratee]] = object;
      });
    });

    return Object.values(map);
  };

  preselectReportFields = (reportType) => {
    const { sortBy, currency, depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses, defaultTimePeriods,
      baseCurrencies, payoutStatus, timeStampList, timeZones, displayMode, defaultTimezone, trafficTypes } = this.state;

    const firstSortBy = sortBy[reportType] && sortBy[reportType][0];

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositMethod, "depositMethod");
    }

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE") {
      this.onValueChange(depositStatus, "depositStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(trafficTypes, "trafficTypes");
    }

    if (reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositPayoutStatus, "depositStatus");
    }

    if(reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "PAYOUT") {
      if (defaultTimezone) {
        this.onValueChange(timeZones.find(t => t.value === defaultTimezone), "timeZone");
      } else {
        this.onValueChange(timeZones[0], "timeZone");
      }
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS") {
      if (reportType === "RUNNING_BALANCE") {
        const rbCurrencies = this.getRunningBalanceCurrencies();
        this.onValueChange(rbCurrencies[0], "currency");
      } else {
        this.onValueChange(currency, "currency");
      }
    }

    if (reportType === "PAYOUT") {
      this.onValueChange(payoutStatus, "payoutStatus");
      const payoutMids = this.getPayoutMids();
      this.onValueChange(payoutMids, "midIds");
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "GROSS_PROFIT") {
      this.onValueChange(baseCurrencies[0], "grossCurrency");
      this.onValueChange(properties[reportType], "properties");
    }

    if (reportType === "SETTLEMENTS") {
      this.onValueChange(settlementStatuses, "settlementStatus");
    }

    if(reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS") {
      const mids = this.getMidsByCurrencyAndTimeZone(false, (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"));
      this.onValueChange(mids, "midIds");
    }

    this.onValueChange(displayMode[0], "displayMode");

    if (reportType === "RUNNING_BALANCE") {
      const sortByDate = sortBy[reportType] && sortBy[reportType].find(e => e.value === "DATE");
      this.onValueChange(sortByDate, "sortBy");
    } else {
      this.onValueChange(firstSortBy, "sortBy");
    }
    this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
  };

  defineReportForm = (reportType) => {
    const { reportForm, reportFormRules, reportFormData, timeStampList } = this.state;

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const payoutForm = new Form ({
      clientIds: reportForm.clientIds,
      companyId: reportForm.companyId,
      payoutStatus: [],
      sortBy: "",
      reportType: reportType,
      displayMode: "",
      sortByDate: reportForm.sortByDate,
      transactionId: "",
      midIds: [],
      timeZone: "",
      timeStampList:  timeStampList
    }, copyArray(reportFormRules.slice(0, 6)).concat([{
      name: "midIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "payoutStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      rules: {
        trim: true,
        required: false,
        pattern: "^([0-9a-zA-z#?!@$%^&*-]+)((, +| +, +| + ,|, |,| ,| , )([0-9a-zA-z#?!@$%^&*-]+))*$"
      }
    }]));

    const grossProfitForm = new Form({
      clientIds: [],
      companyId: reportForm.companyId,
      reportType: reportType,
      displayMode: "",
      sortBy: "",
      sortByDate: reportForm.sortByDate,
      properties: [],
      grossCurrency: ""
    }, copyArray(reportFormRules.slice(0, 4)).concat(
      [{
        name: "properties",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, {
        name: "grossCurrency",
        type: "isString",
        rules: {
          required: true
        }
      }]
    ));

    const transactionsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        companyId: reportForm.companyId,
        displayMode: "",
        reportType: reportType,
        trafficTypes: [],
        midIds: reportForm.midIds || [],
        timeStampList: timeStampList,
        timeZone: "",
      }, reportType === "SERVER_LOGS" ? { orderId: "" } : {}),
      reportType !== "SERVER_LOGS" ? copyArray(reportFormRules) : copyArray(reportFormRules).concat([{
        name: "orderId",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "trafficTypes",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      },{
        name: "midIds",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, ])
    );

    const runningBalanceForm = new Form(
      {
        clientIds: reportForm.clientIds,
        companyId: reportForm.companyId,
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        midIds: [],
        currency: [],
        removeZeroValues: false,
        sortByDate: "DAY",
        hideEmptyRows: true,
        hideEmptyColumns: true
      }, copyArray(reportFormRules.slice(0, 4)).concat(
        [{
          name: "currency",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }]
      )
    );

    const settlementsForm = new Form(
      {
        clientIds: reportForm.clientIds,
        companyId: reportForm.companyId,
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        currency : [],
        midIds: [],
        invoiceNumber: "",
        settlementStatus: [],
        removeZeroValues: false,
        sortByDate: "DAY"
      }, copyArray(reportFormRules.slice(0, 4)).concat(
        [{
          name: "settlementStatus",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "invoiceNumber",
          type: "isPattern",
          rules: {
            required: false,
            pattern: "^[0-9]+$"
          }
        }]
      )
    );

    const performanceForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        companyId: reportForm.companyId,
        displayMode: "",
        reportType: reportType,
        timeZone: "",
        timeStampList: timeStampList,
        email: "",
        ip: ""
      }),
      copyArray(reportFormRules).concat(
        {
          name: "email",
          type: "isString",
          rules: {
            required: false
          }
        },
        {
          name: "ip",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const depositsPayoutsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        companyId: reportForm.companyId,
        displayMode: "",
        reportType: reportType,
        trafficTypes: [],
        midIds: reportForm.midIds || [],
        timeStampList: timeStampList,
        timeZone: "",
        transactionId: ""
      }),
      reportType !== "SERVER_LOGS" ? copyArray(reportFormRules).filter(elem => elem.name !== "transactionId") : copyArray(reportFormRules).filter(elem => elem.name !== "transactionId").concat([{
        name: "orderId",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "trafficTypes",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      },{
        name: "midIds",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, {
        name: "transactionId",
        type: "isString",
        rules: {
          required: false
        }
      }])
    );

    if (reportType !== "SERVER_LOGS") {
      delete transactionsForm.orderId;
    }

    if (reportType === "GROSS_PROFIT") {
      return grossProfitForm;
    } else if (reportType === "PAYOUT") {
      return payoutForm;
    } else if (reportType === "RUNNING_BALANCE") {
      return runningBalanceForm;
    } else if (reportType === "SETTLEMENTS") {
      return settlementsForm;
    } else if (reportType === "PERFORMANCE") {
      return performanceForm;
    } else if (reportType === "DEPOSITS_PAYOUTS") {
      return depositsPayoutsForm;
    } else {
      return transactionsForm;
    }
  };

  onValueChange = (newValue, fieldName) => {
    let { reportForm } = this.state;
    const { clientMids, defaultTimePeriods, isReportErrorFields } = this.state;
    if (typeof newValue === "boolean") {
      reportForm = Object.assign(reportForm, {
        [fieldName]: !newValue
      });
    } else if (newValue.value) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: fieldName === "midIds" || fieldName === "currency" ? [newValue.value] : newValue.value
      });
    } else if (newValue.target) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.target.value
      });
    } else {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    this.setState({
      currentPage: 1
    });

    const reportType = reportForm.reportType;

    if (fieldName === "midIds" && reportType === "RUNNING_BALANCE") {
      reportForm.currency = reportForm.midIds.length ? [clientMids.find(elem => reportForm.midIds.includes(elem.value)).currency] : "";
    } else if (fieldName === "currency" && (reportType === "TRANSACTIONS" || reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS" || reportType === "DEPOSITS_PAYOUTS")) {
      const mids = this.getMidsByCurrencyAndTimeZone(false, (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"));
      reportForm.midIds = mids.map(mid => mid.value);
    } else if (fieldName === "timeZone" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS")) {
      if (reportType === "RUNNING_BALANCE" || reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS") {
        const mids = this.getMidsByCurrencyAndTimeZone(false, (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"));
        reportForm.midIds = mids.map(mid => mid.value);
      } else if (reportType === "PAYOUT") {
        const mids = this.getPayoutMids();
        reportForm.midIds = mids.map(mid => mid.value);
      }
      const currentPeriod = this.state.dropdowns.periods.find(elem => elem.id === this.state.controls.timePeriod.id);
      this.onSelectPeriodChange(currentPeriod, "timePeriod");
    } else if (fieldName === "trafficTypes" && (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS")) {
      const mids = this.getMidsByCurrencyAndTimeZone(false, true);
      reportForm.midIds = mids.map(mid => mid.value);
    }

    const isReportType = fieldName === "reportType";
    if (isReportType) {
      this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
    }

    if (isReportErrorFields) {
      reportForm.isFormValid();
    }

    this.setState({
      reportForm: isReportType ? this.defineReportForm(reportType) : reportForm
    }, () => {
      if (isReportType) {
        this.preselectReportFields(reportType);
      }
    });

    if (fieldName === "sortByDate") {
      this.setState({
        isSortSettingsOpen: false
      });
    }
  };

  getFields = () => {
    const { reportForm, sortBy, currency, payoutStatus, depositMethod, depositStatus,
      depositPayoutStatus, properties, settlementStatuses, trafficTypes } = this.state;

    const isSortByVisible = reportForm.reportType === "PERFORMANCE";

    const baseFields = [
      isSortByVisible ? {
        id: "sortBy",
        type: "select",
        label: "Sort By",
        disabled: !reportForm.reportType,
        options: reportForm.reportType ? sortBy[reportForm.reportType] : []
      } : {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }, {
        id: !isSortByVisible ? "empty" : ""
      }];

    const buttonField = [{
      id: "button",
      type: "button"
    }];

    const reportFields = {
      TRANSACTIONS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {
          id: "trafficTypes",
          type: "multiselect",
          label: "Traffic Type",
          selectedItems: this.mapSelectedItems("trafficTypes", "trafficTypes"),
          items: trafficTypes,
        }],
        [{
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByCurrencyAndTimeZone(false, true),
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
        }, {}, {}], [{}, {}, {
          id:"bulkButton",
          type:"bulkButton"
        }]
      ],
      GROSS_PROFIT: [
        [{
          id: "properties",
          type: "multiselect",
          label: "Properties",
          selectedItems: this.mapSelectedItems("properties", "properties", true),
          items: reportForm.reportType ? properties[reportForm.reportType] : []
        }, {}, {}]
      ],
      SERVER_LOGS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {}],
        [{
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID"
        }, {
          id: "orderId",
          type: "input",
          label: "Search Order ID"
        }, {}]
      ],
      PAYOUT: [
        [{
          id: "midIds",
          type: "multiselect",
          label: "Payout MID",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getPayoutMids(),
        }, {
          id: "payoutStatus",
          type: "multiselect",
          label: "Payout Status",
          selectedItems: this.mapSelectedItems("payoutStatus", "payoutStatus"),
          items: payoutStatus
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID"
        }, {}],
        [{}, {}, { 
          id:"bulkButton",
          type:"bulkButton"
        }]
      ],
      RUNNING_BALANCE: [
        [{
          id: "currency",
          type: "select",
          label: "Currency",
          disabled: false,
          options: this.getRunningBalanceCurrencies()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByCurrencyAndTimeZone(false, false)
        }, {}, {}],
        [{
          id: "hideEmptyRows",
          type: "checkbox",
          label: "Hide Empty Rows"
        }, {
          id: "hideEmptyColumns",
          type: "checkbox",
          label: "Hide Empty Columns"
        }, {}]
      ],
      SETTLEMENTS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "settlementStatus",
          type: "multiselect",
          label: "Statuses",
          selectedItems: this.mapSelectedItems("settlementStatus", "settlementStatuses"),
          items: settlementStatuses
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByCurrencyAndTimeZone(false, false)
        }, {}],
        [{
          id: "invoiceNumber",
          type: "input",
          label: "Search Invoice #"
        }, {}, {}]
      ],
      PERFORMANCE: [
        [{
          id: "email",
          type: "textarea",
          label: "Search Email"
        }, {
          id: "ip",
          type: "textarea",
          label: "Search IP Address"
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      DEPOSITS_PAYOUTS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositPayoutStatus
        }, {
          id: "trafficTypes",
          type: "multiselect",
          label: "Traffic Type",
          selectedItems: this.mapSelectedItems("trafficTypes", "trafficTypes"),
          items: trafficTypes,
        }],
        [{
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByCurrencyAndTimeZone(false, true)
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search"
        }, {}, {}],
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ]
    };

    const buildFields = (type) => {
      const fields = reportFields[type];
      fields.splice(0, 0, baseFields);
      fields[fields.length - 1] = fields[fields.length - 1].concat(buttonField);

      return fields;
    };

    const selectedReportFields = reportFields[reportForm.reportType];

    if (!selectedReportFields) {
      return buildFields('TRANSACTIONS');
    }

    return buildFields(reportForm.reportType);
  };

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp, reportForm, timeZones } = this.state;
    let timeZoneOffset = "00:00";
    if (reportForm.timeZone && timeZones.length) {
      timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }
    const yesterday = Datetime.moment().utc().zone(timeZoneOffset).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  getPeriod(periodType) {
    let timeZoneOffset = "00:00";
    if (this.state) {
      if (this.state.reportForm.timeZone && this.state.timeZones.length) {
        timeZoneOffset = this.state.timeZones.find(zone => zone.value === this.state.reportForm.timeZone).offset;
      }
    }

    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().zone(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year()).month(moment().utc().zone(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset)
      };
    case 3: // last month
      return {
        from: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year()).month(moment().utc().zone(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year()).month(moment().utc().zone(timeZoneOffset).month() - 1)
          .date(moment().utc().zone(timeZoneOffset).month(moment().utc().zone(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset)
      };
    case 5: // last year
      return {
        from: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset).year(moment().utc().zone(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 6: // last hour
      return {
        from: moment().utc().zone(timeZoneOffset).subtract(1, 'hours'),
        to: moment().utc().zone(timeZoneOffset)
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().zone(timeZoneOffset).subtract(6, 'hours'),
        to: moment().utc().zone(timeZoneOffset)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().zone(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZoneOffset)
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      currentPage: 1,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  getMidCurrencies = () => {
    const { clientMids } = this.state;
    if (!clientMids) {
      return [];
    }

    return clientMids;
  };

  getRunningBalanceCurrencies = () => {
    const { clientMids } = this.state;

    if (!clientMids) {
      return [];
    }
    const findCurrency = clientMids.map(mid => mid.currency);

    const setData = new Set(findCurrency);
    const uniqueCurrencyArray = Array.from(setData);

    const uniqueCurrencyObj = uniqueCurrencyArray.map(currency => {
      const obj = {};
      obj.value = currency;
      obj.label = currency;
      return obj;
    });

    return uniqueCurrencyObj;
  };

  getMidsByCurrencyAndTimeZone = (filterByTimeZone, filterByTrafficType) => {
    const { reportForm } = this.state;
    const { clientMids } = this.state;
    if (!clientMids) {
      return [];
    }

    let filteredMids = reportForm.currency ? clientMids.filter(mid => reportForm.currency.includes(mid.currency)) : [];

    if (filterByTimeZone && reportForm.timeZone) {
      filteredMids = filteredMids.filter(mid => reportForm.timeZone === mid.timeZone);
    }

    if (filterByTrafficType && reportForm.trafficTypes) {
      filteredMids = filteredMids.filter(mid => reportForm.trafficTypes.includes(mid.trafficType));
    }

    return filteredMids;
  };

  getPayoutMids = () => {
    const { clientPayoutMids } = this.state;

    if (!clientPayoutMids) {
      return [];
    }

    return clientPayoutMids;
  };

  mapMerchants = () => {
    const { reportForm, companiesClients } = this.state;
    const selectedCompany = reportForm.companyId;
    if (!selectedCompany) {
      return [];
    }

    const field = reportForm["clientIds"];
    const lookup = companiesClients[selectedCompany];

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapSelectedItems = (type, lookupsName, useReportType) => {
    const { reportForm } = this.state;
    const field = reportForm[type];
    const { reportType } = reportForm;
    let lookup = null;
    if (lookupsName === "midIds") {
      if (reportType === "TRANSACTIONS" || reportType === "SETTLEMENTS" || reportType === "RUNNING_BALANCE" || reportType === "DEPOSITS_PAYOUTS") {
        lookup = this.getMidsByCurrencyAndTimeZone(false, (reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS"));
      } else if (reportType === "PAYOUT") {
        lookup = this.getPayoutMids();
      }
    } else if (useReportType) {
      lookup = this.state[lookupsName][reportType];
    } else {
      if (type === "depositStatus" && reportType === "DEPOSITS_PAYOUTS") {
        lookup = this.state.depositPayoutStatus;
      } else {
        lookup = this.state[lookupsName];
      }
    }
    if (!field || !lookup || !Array.isArray(field) || field.some(elem => !lookup.find(lookupElem => elem === lookupElem.value))) {
      return [];
    }
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  getReportColumnIndex = (headerId) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers.map(e => e.headerId).indexOf(headerId);
  };

  filterHeaders = (headers) => {
    const { access, generatedReportType } = this.state;

    return generatedReportType === "PERFORMANCE" ? headers : headers.filter(elem => {
      if (!elem.permission) {
        return true;
      }
     
      const foundPermission = access.find(accessElem => accessElem.permission === elem.permission);
      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });
  };

  filterData = (data) => {
    const { reportsGenerated, access, generatedReportType } = this.state;
    return generatedReportType === "PERFORMANCE" ? data : data.filter((elem, index) => {

      const header = reportsGenerated.headers[index];

      if (!header.permission) {
        return true;
      }

      const foundPermission = access.find(accessElem => accessElem.permission === header.permission);
      if (!foundPermission ) {
        return true;
      }
      
      return foundPermission.state;
    });
  };

  onGenerateReport = () => {
    const { reportForm, timeStamp, timeZones, endpointGenerateMap, currentPage, rowsAmount, periodId, controls, loadingRBReportId, rbIntervalID } = this.state;
    const isReportFormValid = reportForm.isFormValid();
    this.setState({
      reportForm: reportForm,
      isReportErrorFields: true,
      clickButton: true
    });

    if(reportForm.transactionId){
      this.setState({
        enableButton: true
      });
    }

    if (isReportFormValid) {
      this.setState({
        isLoading: true
      });

      const reportFormData = reportForm.data();
      
      const displayMode = reportFormData.displayMode;
      const reportType = reportFormData.reportType;
      if (reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "DEPOSITS_PAYOUTS") {
        reportFormData.transactionId = reportFormData.transactionId.trim();
      }
      //Is it necessary?
      delete reportFormData.sortByDate;

      let dateFormat = "DD.MM.YYYY HH:mm:ss";
      if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
        dateFormat = "DD.MM.YYYY";
      }
      if (reportType === "SETTLEMENTS") {
        delete reportFormData.currency;
      }
      
      const period = this.getPeriod(periodId);

      if (periodId !== 8) {
        //we do not override dates for custom date picker
        timeStamp.fromDateTimeStamp = period.from;
        timeStamp.toDateTimeStamp = period.to;
      }

      let timeZoneOffset = "00:00";
      if (reportForm.timeZone && timeZones.length) {
        timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
      }

      let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZoneOffset).format(dateFormat);
      let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZoneOffset).format(dateFormat);

      if (periodId === 8) {
        fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
        toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
      }

      let endpoint = endpointGenerateMap[reportType][displayMode];
      if (loadingRBReportId) {
        endpoint += `/${loadingRBReportId}`;
      }

      (loadingRBReportId ? crudActions.get : crudActions.post)(
        endpoint,
        Object.assign(reportFormData, {
          fromDate: fromDateTimeStamp,
          toDate: toDateTimeStamp,
          pageNumber: currentPage,
          limit: rowsAmount,
          ...(reportType === "RUNNING_BALANCE" && { timePeriod: controls.timePeriod.value })
        })).then(
        (reportsGenerated) => {
          if (reportsGenerated) {
            if (reportsGenerated.status === "PENDING" && reportsGenerated.id) {
              this.setState({
                loadingRBReportId: reportsGenerated.id,
                savedGeneratedReport: reportFormData,
                savedSelectedCompany: reportFormData.companyId
              }, () => {
                if (!rbIntervalID) {
                  const interval = setInterval(() => {
                    this.onGenerateReport();
                  }, 5000);
                  this.setState({
                    rbIntervalID: interval
                  });
                }
              });
            } else if (reportsGenerated.status && reportsGenerated.id) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: "Oops, something went wrong. Please, try again or change filter parameters.",
                rbIntervalID: null,
                loadingRBReportId: "",
                isLoading: false
              });
              clearInterval(rbIntervalID);
            } else {
              if (displayMode === "LIST_DETAILED") {
                reportsGenerated.isListDetailed = true;
                reportsGenerated.data = reportsGenerated.data.reduce(
                  (finalArray, currentElem) => {
                    finalArray.push({
                      mainRow: true,
                      content: currentElem.mainRow.concat(Array(reportsGenerated.headers.length - currentElem.mainRow.length).fill(""))
                    });

                    finalArray = finalArray.concat(currentElem.subRows.map(elem => {
                      return {
                        mainRow: false,
                        content: Array(reportsGenerated.headers.length - elem.length).fill("").concat(elem)
                      };
                    }));

                    return finalArray;
                  }, []
                );
              } else if (
                reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" ||
                reportType === "PAYOUT" || reportType === "PERFORMANCE" ||
                reportType === "DEPOSITS_PAYOUTS"
              ) {
                reportsGenerated.isHistory = true;
              } else {
                reportsGenerated.isList = true;
              }
              if(reportsGenerated.warningMessage) {
                this.setState({
                  showAlert: true,
                  alertType: "warning",
                  alertMessage: reportsGenerated.warningMessage,
                });
              }
              this.setState({
                rbIntervalID: null,
                loadingRBReportId: "",
                reportsGenerated: reportsGenerated,
                generatedReportType: reportType,
                savedGeneratedReport: reportFormData,
                savedSelectedCompany: reportFormData.companyId,
                isLoading: false,
                cnt: reportsGenerated.cnt,
                lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
              });
              clearInterval(rbIntervalID);
            }
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them in order to continue."
      });
    }
  };

  onExportClick = () => {
    const { reportForm, timeStamp, timeZones, endpointXlsxMap, periodId, controls, loadingRBReportId, rbIntervalID } = this.state;
    const reportFormData = reportForm.data();

    this.setState({
      isExportSettingsOpen: false
    });

    const reportType = reportFormData.reportType;
    const displayMode = reportFormData.displayMode;

    let dateFormat = "DD.MM.YYYY HH:mm:ss";
    if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
      dateFormat = "DD.MM.YYYY";
    }

    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    let timeZoneOffset = "00:00";
    if (reportForm.timeZone && timeZones.length) {
      timeZoneOffset = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }

    let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZoneOffset).format(dateFormat);
    let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZoneOffset).format(dateFormat);

    if (periodId === 8) {
      fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
      toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
    }

    let endpoint = endpointXlsxMap[reportType][displayMode];
    if (loadingRBReportId) {
      endpoint += `/${loadingRBReportId}`;
    }

    if (!loadingRBReportId) {
      this.setState({
        showAlert: true,
        alertType: "info",
        alertMessage: "Export in progress. It might take some time, depends on data volume."
      });
    }

    (loadingRBReportId ? crudActions.get : crudActions.post)(
      endpoint,
      Object.assign(reportFormData, {
        fromDate: fromDateTimeStamp,
        toDate: toDateTimeStamp,
        pageNumber: 1,
        limit: INTEGER_MAX,
        ...(reportType === "RUNNING_BALANCE" && { timePeriod: controls.timePeriod.value })
      })).then(response => {
      if (response.status === "PENDING" && response.id) {
        this.setState({
          loadingRBReportId: response.id
        }, () => {
          if (!rbIntervalID) {
            const interval = setInterval(() => {
              this.onExportClick();
            }, 5000);
            this.setState({
              rbIntervalID: interval
            });
          }
        });
      } else if (response) {
        response.blob().then(
          result => {
            const dateTime = Datetime.moment().format("DD-MM-YYYY HH-mm-ss");
            saveAs(result, `${dateTime} - ${reportType}.xlsx`);
          }
        );
        this.setState({
          rbIntervalID: null,
          loadingRBReportId: ""
        });
        clearInterval(rbIntervalID);
      }
    }).catch(
      err => {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message,
          rbIntervalID: null,
          loadingRBReportId: ""
        });
        clearInterval(rbIntervalID);
      }
    );
  };

  onOpenModalClick = (data, type) => {
    if (type === "json" && (data === "{}" || !data)) {
      return;
    }

    try {
      const modalData = type === "json" ? JSON.parse(data) : data;

      this.setState({
        showModal: true,
        modalType: type,
        modalData
      });
    } catch {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: type === "json" ? "Parsing logs failed." : "Getting notes failed.",
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      historyJson: {}
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onSortByOpen = () => {
    this.setState({
      isSortSettingsOpen: !this.state.isSortSettingsOpen
    });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  isRightAligned = (index) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers[index].headerType === "NUMBER";
  };

  triggerButtonEvent = (headers, headerIndex, row, tableData, tableDataType) => {
    const { savedGeneratedReport, savedSelectedCompany } = this.state;
    const filterHeders = this.filterHeaders(headers);
  
    const buttonSettings = filterHeders.at(headerIndex);
  
    const headersIndexes = headers.filter(elem => buttonSettings.fields.includes(elem.headerId)).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );

    const data = {};

    headersIndexes.forEach(
      elem => {
        const foundHeader = headers[elem];
        data[foundHeader.headerId] = row[elem];
      }
    );

    if (buttonSettings.endpoint.includes("v1/report-buttons/transactions")) {
      this.setState({
        isModalLoading: true,
        showModal: true,
        modalType: tableDataType,
        modalTitle: "Logs",
        modalData: ""
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      }).then(
        (response) => {
          this.setState({
            isModalLoading: false,
            modalData: JSON.parse(JSON.stringify(response))
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              showModal: false,
              modalTitle: "",
            });
          }
        }
      );
    } else {
      crudActions.post(buttonSettings.endpoint, data, {
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      }).then(
        (response) => {
          const statusMessage = response.message;
          const endpoint = buttonSettings.endpoint; 

          if (endpoint === "/v1/report-buttons/deposits/check-status" || savedGeneratedReport.reportType === "DEPOSITS_PAYOUTS"){
            const status = response.messageType;
            if (status === "SUCCESS") {
              this.setState({
                showAlert: true,
                alertType: "success",
                alertMessage: response.message
              });
            } else if (status === "ERROR") {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: response.message
              });
            } else if (status === "NEUTRAL") {
              this.setState({
                showAlert: true,
                alertType: "info",
                alertMessage: response.message
              });
            }
          } else {
            this.setState({ 
              showAlert: true,
              alertType: "success",
              alertMessage:  endpoint === "/v1/report-buttons/payouts/check-status" ? statusMessage : "Operation successful."
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    } 
  };

  sendBulkPostback = () => {
    const { reportsGenerated, reportForm, savedSelectedCompany, savedGeneratedReport} = this.state;
    const headers = reportsGenerated.headers;
    const data = reportsGenerated.data;
    const transactionIds = [];
    let index = null;
    if(data && headers){
      if(reportForm.reportType === "TRANSACTIONS"||reportForm.reportType === "RISK_AND_SECURITY") {
        index = headers.map(e => e.headerName).indexOf("Trans. ID");
      }
      if(reportForm.reportType === "PAYOUT") {
        index = headers.map(e => e.headerName).indexOf("Payout ID");
      }
      data.forEach(d => {
        transactionIds.push(d[index]);
      });
    }
    const objectTransactionsIds = {};
    objectTransactionsIds.ids = transactionIds;
    if(reportForm.reportType === "TRANSACTIONS") {
      (crudActions.post('v1/report-buttons/deposit/bulk', objectTransactionsIds, {
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      }
      )).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successful."
          });}
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }

    if(reportForm.reportType === "PAYOUT") {
      (crudActions.post('/v1/report-buttons/payout/bulk', objectTransactionsIds, {
        Company: savedSelectedCompany,
        Client: savedGeneratedReport.clientIds
      })).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successful."
          });}
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon" 
    || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
    || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
      if (event.target.textContent) {
        pageNum = event.target.textContent;
      }
   
    this.setState({currentPage: pageNum}, () => {
      this.onGenerateReport();
    }); 
  };

  getIcon = () => {
    switch (whiteLabel) {
    case "t365":
      return ReportIconT365;
    case "pacificCapital":
      return ReportIconPacificCapital;
    case "trustbucks":
      return ReportIconTrustbucks;
    default:
      return ReportIcon;
    }
  };

  render() {
    const controls = {...this.state.controls};
    const {
      clickButton,
      cnt,
      currentPage,
      dateFieldDisabled,
      enableButton,
      generatedReportType,
      modalData,
      modalType,
      isLoading,
      isLoadingLookups,
      isModalLoading,
      isSortSettingsOpen,
      isExportSettingsOpen,
      reportForm,
      reportsGenerated,
      reportType,
      rowsAmount,
      showModal,
      sortByDate,
      timeStamp,
      timeStampList,
      timeZones,
      showAlert,
      alertType,
      alertMessage
    } = this.state;
    
    const rows = this.getFields();

    const totalPageCount = Math.ceil(cnt / rowsAmount);
    
    if (!isSortSettingsOpen) {
      this.sortNode = null;
    }

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    if (!reportForm.originalData) {
      return <Row flexGrow={ 1 } className='module generateReports' vertical='start'>
        <span/>
      </Row>;
    }

    const isDateTime = reportForm.reportType !== "SETTLEMENTS" && reportForm.reportType !== "GROSS_PROFIT";

    const isTimeVisible = reportForm.reportType !== "RUNNING_BALANCE" || (reportForm.reportType === "RUNNING_BALANCE" && controls.timePeriod.value === "custom");

    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module generateReports` } vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Generate Report"
            lastUpdate={ this.state.lastUpdate }
            img={ this.getIcon() } />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading className={ whiteLabel }>
                  <Panel.Title>
                    GENERATE REPORT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    {isLoadingLookups ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Report Type </label>
                              <Select
                                id="reportType"
                                name="reportType"
                                value={  reportForm.reportType }
                                required={ true }
                                clearable={ false }
                                disabled={ isLoading }
                                onChange={ (value) => this.onValueChange(value, 'reportType') }
                                options={ reportType }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Merchant </label>
                              <input
                                id="client"
                                name="client"
                                className="form-control"
                                value={ this.state.clientName || '' }
                                disabled
                              />
                            </Column>
                            {(reportForm.reportType ==="TRANSACTIONS" || reportForm.reportType === "PAYOUT" || reportForm.reportType === "DEPOSITS_PAYOUTS") ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Time Stamp </label>
                                <Select
                                  id="timeStampList"
                                  name="timeStampList"
                                  value={  reportForm.timeStampList }
                                  required={ true }
                                  clearable={ false }
                                  disabled={ isLoading }
                                  onChange={ (value) => this.onValueChange(value, 'timeStampList') }
                                  options={ timeStampList }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                <span/>
                              </Column>
                            )}
                            {(reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" || reportForm.reportType === "DEPOSITS_PAYOUTS" ) ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Time Zone </label>
                                <Select
                                  id="timeZone"
                                  name="timeZone"
                                  className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                  value={ reportForm.timeZone }
                                  required={ true }
                                  clearable={ false }
                                  disabled={ isLoading }
                                  onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                  options={ timeZones }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                <span/>
                              </Column>
                            )}
                          </Row>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Time Period </label>
                            <Select
                              id="timePeriod"
                              name="timePeriod"
                              value={ controls.timePeriod.value || '' }
                              required={ true }
                              clearable={ false }
                              enableSort={ false }
                              disabled={ isLoading }
                              onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                              options={ this.state.dropdowns.periods }
                            />
                          </Column>
                          {isTimeVisible ? (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label>From Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                timeFormat="HH:mm"
                                showTimeSelect={ isDateTime }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                                disabled={ dateFieldDisabled || isLoading }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                              />
                            </Column>
                          ) : (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                          )}
                          {isTimeVisible ? (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label>To Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                timeFormat="HH:mm"
                                showTimeSelect={ isDateTime }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                                disabled={ dateFieldDisabled || isLoading }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                              />
                            </Column>
                          ) : (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                          )}
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                            <span/>
                          </Column>
                        </Row>
                        {rows.map((row, index) => {
                          return (
                            <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                              {[2, 4].map(count => {
                                return (
                                  <Row key={ index + " " + count } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                                    {row.slice(count - 2, count).map((field, fieldIndex) => {
                                      return field.id !== "empty" && (
                                        <Column
                                          key={ field.id + " " + index + " " + fieldIndex }
                                          flexGrow={ field.flexGrow !== undefined ? parseFloat(field.flexGrow) : 1 }
                                          vertical='end'
                                          alignSelf='start'
                                          className="input-column"
                                        >
                                          { field.type !== "checkbox" && !field.notShowOnCondition && (
                                            <label> { field.label } </label>
                                          )}

                                          {field.type === "select" && !field.notShowOnCondition ? (
                                            <Select
                                              className={ reportForm.errors.has(field.id) ? 'error-field' : "" }
                                              value={ field.id === "midIds" || field.id === "currency" ? reportForm[field.id].toString() : reportForm[field.id] }
                                              disabled={ field.disabled || isLoading }
                                              required={ true }
                                              clearable={ false }
                                              options={ field.options }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                            />
                                          ) : field.type === "multiselect" && !field.notShowOnCondition ? (
                                            <Multiselect
                                              isError={ reportForm.errors.has(field.id) }
                                              selectedItems={ field.selectedItems }
                                              disabled={ field.disabled || isLoading }
                                              items={ field.items }
                                              type={ field.id }
                                              withId={ field.withId }
                                              onChange={ this.onValueChange }
                                            />
                                          ) : field.type === "input" && !field.notShowOnCondition ? (
                                            <input
                                              className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                              value={ reportForm[field.id] }
                                              disabled={ field.disabled || isLoading }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                            />
                                          ) : field.type === "textarea" && !field.notShowOnCondition ? (
                                            <TextareaAutosize 
                                              cacheMeasurements
                                              value={ reportForm[field.id] }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                              className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                              disabled={ field.disabled || isLoading }
                                            />
                                          ) : field.type === "bulkButton" && enableButton === true ? (
                                            <Button
                                              type="submit"
                                              className="btn defaultBtn"
                                              disabled={ isLoading }
                                              style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                              onClick={ this.sendBulkPostback }
                                            >
                                              SEND BULK POSTBACKS
                                            </Button> 
                                          ) : field.type === "checkbox" && !field.notShowOnCondition ? (
                                            <Row className="styled-input--square" wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                              <div className="styled-input-single">
                                                <input
                                                  id={ field.id }
                                                  type="checkbox"
                                                  checked={ reportForm[field.id] }
                                                  disabled={ isLoading }
                                                  onChange={ () => this.onValueChange(reportForm[field.id], [field.id]) }
                                                />
                                                <label style={ { fontWeight: "normal" } } htmlFor={ field.id }> { field.label } </label>
                                              </div>
                                            </Row>
                                          ) : field.type === "button" && !field.notShowOnCondition ? (
                                            <Button
                                              type="submit"
                                              className="btn defaultBtn"
                                              disabled={ isLoading }
                                              style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                              onClick={ this.onGenerateReport }
                                            >
                                              GENERATE
                                            </Button>
                                          ) : (
                                            <span/>
                                          )}
                                        </Column>
                                      );
                                    })}
                                  </Row>);
                              })}
                            </Row>);
                        })}
                      </>
                    )}
                      
                  </div>
                </Panel.Body>
              </Panel>
              { isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> REPORT GENERATED </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : reportsGenerated.headers ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      REPORT GENERATED
                    </Panel.Title>
                    <div className="settings-panel">
                      { generatedReportType !== "TRANSACTIONS" && <span className="setting" onClick={ () => this.onSortByOpen() }>
                        <svg className="setting-icon" viewBox="0 0 1792 1792">
                          <path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"/>
                        </svg>
                        <span className="text"> { sortByDate.find(elem => elem.value === reportForm.sortByDate).label } </span>
                      </span> }
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd"/>
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd"/>
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    { isSortSettingsOpen && (
                      <div ref={ node => this.sortNode = node } className="sort-settings">
                        {
                          sortByDate.map(elem => {
                            return <p key={ elem.value } className="sort-setting-item" onClick={ () => this.onValueChange(elem, 'sortByDate') }> { elem.label } </p>;
                          })
                        }
                      </div>
                    )}
                    { isExportSettingsOpen && (
                      <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                      </div>
                    )}
                  </Panel.Heading>
                  <Panel.Body>
                    <div className={ `panel-content report-content ${generatedReportType.toLowerCase()}` }>
                      <Table className="table table-striped">
                        <thead>
                          <tr style={ {backgroundColor: '#dfdfdf'} }>
                            {this.filterHeaders(reportsGenerated.headers).map((column, i) => {
                              return column.headerId !== "ip_country" && (
                                <th
                                  key={ i }
                                  className={ column.headerId === "success_rate" || this.isRightAligned(i) ? "text-right" : (column.headerType === "JSON" || column.headerType === "TEXT" || column.headerName === "Action") ? "text-center" : "" }
                                >
                                  { column.headerName }
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {reportsGenerated.data.map((element, i) => {
                            return [ reportsGenerated.isHistory && (
                              <tr key={ i }>
                                {this.filterData(element).map((tableData, j) => {
                                  return this.filterHeaders(reportsGenerated.headers)[j].headerType === "TEXT" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${!tableData ? "no-history" : "history"}` }
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : this.filterHeaders(reportsGenerated.headers)[j].headerType === "JSON" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="text-center history"
                                      onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element, tableData, "json") }
                                    >
                                      View
                                    </td>
                                  ) : this.filterHeaders(reportsGenerated.headers)[j].headerType === "BUTTON" ? (
                                    <td key={ i.toString() + j.toString() }>
                                      <Button
                                        className={ `btn ${reportsGenerated.headers[j].headerName === 'Reject' ? 'red-button' : 'action-button'} ${this.filterHeaders(reportsGenerated.headers)[j].permission === "CHECK_STATUS" && element[element.length - 1] === "false" ? 'grey-button' : 'action-button'} ${generatedReportType === "PERFORMANCE" ? (tableData ? "green" : "orange") : "green"}` }
                                        disabled={ generatedReportType === "PERFORMANCE" }
                                        onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element) }
                                      >
                                        { generatedReportType === "PERFORMANCE" ? (tableData ? "Unblock" : "Block") : this.filterHeaders(reportsGenerated.headers)[j].headerName }
                                      </Button>
                                    </td>
                                  ) : this.filterHeaders(reportsGenerated.headers)[j].headerId !== "ip_country" && (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ this.filterHeaders(reportsGenerated.headers)[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }
                                      style={ { minWidth: this.filterHeaders(reportsGenerated.headers)[j].headerId === "ip" ? "170px" : "100px" } }
                                    >
                                      { this.filterHeaders(reportsGenerated.headers)[j].headerId === "ip" && reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] && (
                                        <img
                                          alt={ reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] }
                                          style={ { width: "32px", marginRight: "3px" } }
                                          src={ `http://purecatamphetamine.github.io/country-flag-icons/3x2/${reportsGenerated.data[i][this.getReportColumnIndex('ip_country')]}.svg` }/>
                                      )}
                                      { tableData }
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            ), reportsGenerated.isListDetailed && (
                              <tr key={ i } className={ element.mainRow ? "main-row" : "" } style={ { backgroundColor: !element.mainRow ? '#FFFFFF' : '' } }>
                                { element.content.map((tableData, j) => {
                                  return <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right" : "" }>
                                    { tableData }
                                  </td>;
                                })
                                }
                              </tr>
                            ), reportsGenerated.isList && (
                              <tr key={ i } style={ {backgroundColor: '#FFFFFF'} }>
                                { element.map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" && tableData) ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="history"
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : (
                                    <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right" : "" }>
                                      { tableData }
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            )];
                          })}
                          {reportsGenerated.total && (
                            <tr className="main-row">
                              {reportsGenerated.total.map((elem, i) => {
                                return <td key={ i }>
                                  { elem }
                                </td>;
                              })}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <span/>
              )}
              {clickButton && totalPageCount > 0 && (
                <Panel style={ {marginTop : "-23px", borderColor : "white", maxHeight: "105px", minHeight: "100px"} }>
                  <Panel.Body>
                    <div className="pagination">
                      <Pagination 
                        count={ totalPageCount } 
                        variant="outlined" 
                        shape="rounded" 
                        page={ Number(currentPage) }
                        onClick= { this.handleChangePage } 
                      />
                    </div>
                  </Panel.Body>
                  
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ alertType !== "error" ? getColor(whiteLabel) : "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

        <Modal
          title={ modalType === "json" ? "Logs" : "Transaction Notes" }
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ isModalLoading }
        />

      </Row>
    );
  }
}

export default GenerateReport;